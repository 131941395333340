<template>
  <!-- 公司简介 -->
  <div class="CompanyProfile">
    <!-- 公司简介 -->
    <div class="mainBodyA wow fadeInUp animated">
      <div class="noticetit marginT40">公司简介</div>
      <div class="companyintro clear marginT20">
        <i class="line line1"></i>
        <i class="line line2"></i>
        <div class="companyintro_img fl">
          <img :src="enterpriseObj.enterprise.imageUrl" />
        </div>
        <div class="fl introduction_con">
          <div class="introduction_con_tit marginT15">COMPANY INTRODUCTION</div>
          <div class="companyintro_con marginT15">
            <div class="companyintro_con_tit">
              {{ enterpriseObj.enterprise.synopsisTitle }}
            </div>
            <div
              class="companyintro_con_p marginT15 fontsize16"
              v-html="enterpriseObj.enterprise.synopsis"
            ></div>
          </div>
        </div>
      </div>
      <div class="companytit marginT40">
        <!-- <div class="on fontsize18">公司大楼</div> -->
        <div class="fontsize18">企业风采</div>
      </div>
      <div class="companycon">
        <swiperCpt
          class="swiperQualification companycon_ul marginT20"
          ref="QualificationSwiper"
          :delay="3000"
          :loop="true"
          :autoplay="true"
          :slidesPerView="3"
          sid="QualificationSwiper"
          v-if="enterpriseObj.imageManages.length > 0"
        >
          <div
            class="swiper-slide"
            v-for="(item, index) in enterpriseObj.imageManages"
            :key="index"
          >
            <li>
              <img
                :src="$store.state.baseUrl + item.imageUrl"
                :alt="'companyStyle' + index"
              />
            </li>
          </div>
        </swiperCpt>
      </div>
    </div>
    <!-- 服务特色 -->
    <div class="wow fadeInUp animated">
      <div class="noticetit marginT40">服务特色</div>
      <div class="companybg marginT15">
        <div class="mainBodyA">
          <ul class="companyTul">
            <li
              class="wow fadeInUp animated"
              :style="{
                'animation-delay': index * 0.6 + 's',
                '-webkit-animation-delay': index * 0.6 + 's',
              }"
              v-for="(item, index) in enterpriseObj.serviceFeatures"
              :key="index"
            >
              <div class="ico">
                <img :src="$store.state.baseUrl + item.imageUrl" />
              </div>
              <div class="companyTul_tit marginT10 fontsize20 colorf3c">
                {{ item.title }}
              </div>
              <div class="companyTul_p marginT20" v-html="item.content"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 愿景理念 -->
    <div class="mainBodyA wow fadeInUp animated">
      <div class="noticetit marginT40">愿景理念</div>
      <div class="marginT40 idea">
        <div class="idea_line1"></div>
        <div class="idea_line2"></div>
        <div class="idea_block1"></div>
        <div class="idea_block2"></div>
        <div class="ideacon">
          <div class="idea_ico">
            <img :src="enterpriseObj.enterprise.conceptImageUrl" />
          </div>
          <div class="idea_t">
            {{ enterpriseObj.enterprise.conceptTitle }}
          </div>
          <div
            class="idea_p"
            v-html="enterpriseObj.enterprise.conceptContent"
          ></div>
        </div>
        <div class="ideacon">
          <div class="idea_ico">
            <img :src="enterpriseObj.enterprise.visionImageUrl" />
          </div>
          <div class="idea_t">
            {{ enterpriseObj.enterprise.visionTitle }}
          </div>
          <div
            class="idea_p"
            v-html="enterpriseObj.enterprise.visionContent"
          ></div>
        </div>
      </div>
      <div class="noticetit marginT40">合作伙伴</div>
      <ul class="partners marginT20">
        <li v-for="(item, index) in enterpriseObj.partners" :key="index">
          <img
            :src="$store.state.baseUrl + item.enterpriseLogo"
            :alt="item.enterpriseName"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import swiperCpt from "@/components/common/swiperCpt.vue";

export default {
  data() {
    return {
      enterpriseObj: {
        // 公司简介
        enterprise: {
          synopsisTitle: "广州工控服务管理有限公司简介",
          synopsis:
            "广州工控服务管理有限公司是广州市属国企广州工业投资控股集团旗下一家综合管理服务公司。工控服务围绕“现代工业综合服务商”的定位，通过实施标准化、专业化、科技化、生态化、资本化战略，构建现代工业服务产业生态圈，以产业园区、生产基地、城市公共、大型场馆等物业形态为主打，以机电运维服务、工厂业务外包、园区运营服务、企业总部服务等专业服务为特色，依托基础物业管理向综合性服务延伸，同时注入科技力量，为业主和客户提供全方位、专业化、高品质的服务。",
          imageUrl: require("../../assets/images/company/intro/jieshaopeitu.png"),
          conceptImageUrl: require("../../assets/images/company/intro/yuanjing.png"), // 愿景链接
          conceptTitle: "愿景", // 愿景标题
          conceptContent: "成为创新型现代工业服务的引领者", // 愿景内容
          visionImageUrl: require("../../assets/images/company/intro/linian.png"), // 理念内容
          visionTitle: "理念", // 理念内容
          visionContent: "专业铸就品质 · 服务创造价值", // 理念内容
        },
        // 服务特色
        serviceFeatures: [],
        //公司风采
        imageManages: [],
        //愿景理念
        visionConcepts: [],
        // 合作伙伴
        partners: [],
      },
      // dataObj: {
      //     // 公司简介
      //     enterprise: {
      //         synopsisTitle: "广州工控服务管理有限公司简介",
      //         synopsis:
      //             "广州工控服务管理有限公司是广州市属国企广州工业投资控股集团旗下一家综合管理服务公司。工控服务围绕“现代工业综合服务商”的定位，通过实施标准化、专业化、科技化、生态化、资本化战略，构建现代工业服务产业生态圈，以产业园区、生产基地、城市公共、大型场馆等物业形态为主打，以机电运维服务、工厂业务外包、园区运营服务、企业总部服务等专业服务为特色，依托基础物业管理向综合性服务延伸，同时注入科技力量，为业主和客户提供全方位、专业化、高品质的服务。",
      //         imageUrl: require("../../assets/images/company/intro/jieshaopeitu.png"),
      //         conceptImageUrl: require("../../assets/images/company/intro/yuanjing.png"), // 愿景链接
      //         conceptTitle: "愿景", // 愿景标题
      //         conceptContent: "成为创新型现代工业服务的引领者", // 愿景内容
      //         visionImageUrl: require("../../assets/images/company/intro/linian.png"), // 理念内容
      //         visionTitle: "理念", // 理念内容
      //         visionContent: "专业铸就品质 · 服务创造价值" // 理念内容
      //     },
      //     // 服务特色
      //     serviceFeatures: [
      //         {
      //             imageUrl: require("../../assets/images/company/intro/icon1.png"),
      //             title: "综合多元",
      //             content:
      //                 "以产业园区、生产基地、城市公共、大型场馆等物业形态为主打，依托基础物业管理向综合性服务进行延伸。"
      //         },
      //         {
      //             imageUrl: require("../../assets/images/company/intro/icon2.png"),
      //             title: "专业特色",
      //             content:
      //                 "涵盖机电运维服务、工厂业务外包、园区运营服务、企业总部服务等专业领域，构建现代工业服务产业生态圈。"
      //         },
      //         {
      //             imageUrl: require("../../assets/images/company/intro/icon3.png"),
      //             title: "定制服务",
      //             content:
      //                 " 充分考虑每一位业主和客户需求，提供专属的定制化服务。"
      //         },
      //         {
      //             imageUrl: require("../../assets/images/company/intro/icon4.png"),
      //             title: "高端品质",
      //             content:
      //                 "借鉴世界知名房地产咨询机构五大行之一仲量联行的运营管理模式，推行6M服务标准，确保高端品质。"
      //         },
      //         {
      //             imageUrl: require("../../assets/images/company/intro/icon5.png"),
      //             title: "科技赋能",
      //             content:
      //                 "整合应用互联网、移动互联网、物联网、AI人工智能等科技手段为服务赋能、提升品质。"
      //         }
      //     ],
      //     //公司风采
      //     imageManages: [
      //         {
      //             imageUrl: require("../../assets/images/company/intro/fengcai1.png")
      //         },
      //         {
      //             imageUrl: require("../../assets/images/company/intro/fengcai2.png")
      //         },
      //         {
      //             imageUrl: require("../../assets/images/company/intro/fengcai3.png")
      //         }
      //     ],
      //     // 合作伙伴
      //     partners: [
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo01.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo02.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo03.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo04.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo05.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo06.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo07.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo08.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo09.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/logo010.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo1.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo2.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo3.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo4.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo5.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo6.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo7.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo8.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo9.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo10.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo11.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo12.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo13.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo14.png"),
      //             enterpriseName: "图片"
      //         },
      //         {
      //             url: "#",
      //             enterpriseLogo: require("../../assets/images/company/intro/hcx4-logo16.png"),
      //             enterpriseName: "图片"
      //         }
      //     ]
      // }
    };
  },
  computed: {
    // imageList() {
    //     let arr = this.enterpriseObj.imageManages || [],
    //         newArr = [];
    //     for (var i = 0; i < arr.length; i += 3) {
    //         newArr.push(arr.slice(i, i + 3));
    //     }
    //     return newArr;
    // }
  },
  mounted() {
    this.getOnfoList();
    new this.$wow.WOW({
      live: false,
      offset: 0,
    }).init();
  },
  methods: {
    async getOnfoList() {
      let resData = await this.$request.getAboutusProfile({
        enterpriseId: "e230b713-95c4-40c0-89de-f73d645e25a0",
        imageColumnId: 48,
      });
      if (resData.code === 200) {
        // console.log("resData", resData);
        this.enterpriseObj = resData;
        this.enterpriseObj.enterprise.conceptImageUrl =
          this.$store.state.baseUrl + resData.enterprise.conceptImageUrl;
        this.enterpriseObj.enterprise.visionImageUrl =
          this.$store.state.baseUrl + resData.enterprise.visionImageUrl;
        this.enterpriseObj.enterprise.imageUrl =
          this.$store.state.baseUrl + resData.enterprise.imageUrl;
        //
      }
    },
  },
  components: {
    swiperCpt,
  },
};
</script>

<style lang="scss" scoped>
.companyintro {
  position: relative;
  .companyintro_img {
    width: 33.65vw;
    height: 22.71vw;
    overflow: hidden;
    position: relative;
    &:hover {
      img {
        transform: scale(1.06, 1.06);
      }
      z-index: 0;
    }

    img {
      width: 33.65vw;
      transition: 0.6s ease-in-out;
    }
  }
  .line {
    display: block;
    position: absolute;
    z-index: 9;
    &.line1 {
      width: 0.16vw;
      height: 3.65vw;
      left: 0.52vw;
      bottom: -1.88vw;
      background-color: #f3ca00;
    }
    &.line2 {
      left: -0.21vw;
      bottom: -1.04vw;
      width: 3.65vw;
      height: 0.16vw;
      background-color: #f3ca00;
    }
  }
}

.introduction_con {
  width: 31.25vw;
  margin-left: -2.4vw;
}

.introduction_con_tit {
  color: #333333;
  opacity: 0.15;
  font-weight: 700;
  font-size: 1.88vw;
}

.companyintro_con {
  width: 100%;
  padding: 1.56vw;
  box-sizing: border-box;
  background: #474747;
  color: #ffffff;
  font-weight: normal;
  position: relative;
  z-index: 11;
  .companyintro_con_tit {
    padding-left: 1.3vw;
    color: #f3ca00;
    font-size: 1.04vw;
    font-weight: bold;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 3.65vw;
      height: 0.21vw;
      margin-top: 0.52vw;
      background-color: #f3ca00;
    }
  }

  .companyintro_con_p {
    line-height: 1.56vw;
    text-indent: 1.67vw;
    letter-spacing: 0.1vw;
    text-align: justify;
    word-break: normal;
  }
}

.companytit {
  width: 100%;
  position: relative;
  word-spacing: -0.05vw;
  font-size: 0;
  word-spacing: -0.05vw;
  text-align: center;
  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 27.55vw;
    height: 0.05vw;
    background-color: #cccccc;
  }
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 27.55vw;
    height: 0.05vw;
    background-color: #cccccc;
  }

  div {
    display: inline-block;
    width: 5.83vw;
    height: 1.98vw;
    line-height: 1.98vw;
    background-color: #eeeeee;
    margin-right: 0.52vw;
    color: #333333;
    &.on {
      background: #f3ca00;
      color: #ffffff;
    }
  }
}

.companycon_ul {
  word-spacing: -0.05vw;
  font-size: 0;
  word-spacing: -0.05vw;
  li {
    display: inline-block;
    // margin-right: 1.04vw;
    margin: 0 0.52vw;
    text-align: center;
    width: 20.1vw;
    height: 13.33vw;
    overflow: hidden;
    transition: 0.6s;
    &:hover {
      transform: scale(1.02, 1.02);
    }
    // &:nth-child(3n + 3) {
    //   margin-right: 0;
    // }
  }
}
.companybg {
  width: 100%;
  height: auto;
  padding-top: 3.65vw;
  padding-bottom: 1.04vw;
  background: url(../../assets/images/company/intro/bg.png) no-repeat;
  background-size: 100% 100%;
  .companyTul {
    word-spacing: -0.05vw;
    font-size: 0;
    word-spacing: -0.05vw;
    li {
      width: 9.38vw;
      display: inline-block;
      vertical-align: top;
      margin-right: 3.85vw;
      &:last-child {
        margin-right: 0;
      }
      .ico {
        text-align: center;
        img {
          display: inline-block;
        }
      }
    }
    .companyTul_tit {
      text-align: center;
    }
    .companyTul_p {
      width: 9.38vw;
      font-size: 0.83vw;
      line-height: 1.67vw;
      color: #ffffff;
      margin: 0 auto;
      text-align: justify;
      word-break: normal;
    }
  }
}

.idea {
  width: 100%;
  height: 12.71vw;
  background: url(../../assets/images/company/intro/beijingtu.png) no-repeat;
  background-size: 100%;
  word-spacing: -0.05vw;
  font-size: 0;
  word-spacing: -0.05vw;
  padding: 1.04vw 0.52vw 1.82vw;
  box-sizing: border-box;
  position: relative;
  .idea_block1 {
    display: block;
    width: 0.89vw;
    height: 0.89vw;
    position: absolute;
    left: 1.77vw;
    bottom: 0.36vw;
    content: "";
    background: #f3ca00;
  }
  .idea_block2 {
    display: block;
    width: 1.46vw;
    height: 1.46vw;
    position: absolute;
    left: 0;
    bottom: -0.63vw;
    content: "";
    background: #f3ca00;
  }
  .idea_line1 {
    width: 3.65vw;
    height: 0.16vw;
    position: absolute;
    display: block;
    right: 0;
    top: -0.36vw;
    bottom: -0.63vw;
    content: "";
    background-color: #f3ca00;
  }
  .idea_line2 {
    width: 0.16vw;
    height: 3.65vw;
    position: absolute;
    display: block;
    right: 0.68vw;
    top: -1.04vw;
    content: "";
    background-color: #f3ca00;
  }
  .ideacon {
    width: 50%;
    display: inline-block;
    border-right: 0.05vw solid #cccccc;
    box-sizing: border-box;
    &:last-child {
      border: none;
    }
    .idea_ico {
      width: 4.48vw;
      height: 4.48vw;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }

    .idea_t {
      font-size: 1.25vw;
      color: #333;
      font-weight: bold;
      text-align: center;
      margin-top: 1.04vw;
    }

    .idea_p {
      font-size: 0.94vw;
      color: #666666;
      margin-top: 0.78vw;
      text-align: center;
    }
  }
}

.partners {
  word-spacing: -0.05vw;
  font-size: 0;
  word-spacing: -0.05vw;
  margin-bottom: 0.2604vw;
  li {
    width: 10.16vw;
    margin-right: 2.86vw;
    margin-bottom: 1.82vw;
    display: inline-block;
    &:nth-child(5n + 5) {
      margin-right: 0;
    }
    img {
      width: 100%;
    }
  }
}
@media (min-width: 1920px) {
  .companyintro {
    position: relative;
    .companyintro_img {
      width: 646px;
      height: 436px;
      overflow: hidden;
      position: relative;
      &:hover {
        img {
          transform: scale(1.06, 1.06);
        }
        z-index: 0;
      }

      img {
        width: 646px;
        transition: 0.6s ease-in-out;
      }
    }
    .line {
      display: block;
      position: absolute;
      z-index: 9;
      &.line1 {
        width: 3px;
        height: 70px;
        left: 10px;
        bottom: -36px;
        background-color: #f3ca00;
      }
      &.line2 {
        left: -4px;
        bottom: -20px;
        width: 70px;
        height: 3px;
        background-color: #f3ca00;
      }
    }
  }

  .introduction_con {
    width: 600px;
    margin-left: -46px;
  }

  .introduction_con_tit {
    color: #333333;
    opacity: 0.15;
    font-weight: 700;
    font-size: 36px;
  }

  .companyintro_con {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    background: #474747;
    color: #ffffff;
    font-weight: normal;
    position: relative;
    z-index: 11;
    .companyintro_con_tit {
      padding-left: 25px;
      color: #f3ca00;
      font-size: 20px;
      font-weight: bold;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 70px;
        height: 4px;
        margin-top: 10px;
        background-color: #f3ca00;
      }
    }

    .companyintro_con_p {
      line-height: 30px;
      text-indent: 32px;
      letter-spacing: 2px;
      text-align: justify;
      word-break: normal;
    }
  }

  .companytit {
    width: 100%;
    position: relative;
    word-spacing: -1px;
    font-size: 0;
    word-spacing: -1px;
    text-align: center;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 529px;
      height: 1px;
      background-color: #cccccc;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 529px;
      height: 1px;
      background-color: #cccccc;
    }

    div {
      display: inline-block;
      width: 112px;
      height: 38px;
      line-height: 38px;
      background-color: #eeeeee;
      margin-right: 10px;
      color: #333333;
      &.on {
        background: #f3ca00;
        color: #ffffff;
      }
    }
  }

  .companycon_ul {
    word-spacing: -1px;
    font-size: 0;
    word-spacing: -1px;
    li {
      display: inline-block;
      margin: 0 10px;
      text-align: center;
      width: 386px;
      height: 256px;
      overflow: hidden;
      transition: 0.6s;
      &:hover {
        transform: scale(1.02, 1.02);
      }
      //   &:nth-child(3n + 3) {
      //     margin-right: 0;
      //   }
    }
  }
  .companybg {
    width: 100%;
    height: auto;
    padding-top: 70px;
    padding-bottom: 20px;
    background: url(../../assets/images/company/intro/bg.png) no-repeat;
    background-size: 100% 100%;
    .companyTul {
      word-spacing: -1px;
      font-size: 0;
      word-spacing: -1px;
      li {
        width: 180px;
        display: inline-block;
        vertical-align: top;
        margin-right: 74px;
        &:last-child {
          margin-right: 0;
        }
        .ico {
          text-align: center;
          img {
            display: inline-block;
          }
        }
      }
      .companyTul_tit {
        text-align: center;
      }
      .companyTul_p {
        width: 180px;
        font-size: 16px;
        line-height: 32px;
        color: #ffffff;
        margin: 0 auto;
        text-align: justify;
        word-break: normal;
      }
    }
  }

  .idea {
    width: 100%;
    height: 244px;
    background: url(../../assets/images/company/intro/beijingtu.png) no-repeat;
    background-size: 100%;
    word-spacing: -1px;
    font-size: 0;
    word-spacing: -1px;
    padding: 20px 10px 35px;
    box-sizing: border-box;
    position: relative;
    .idea_block1 {
      display: block;
      width: 17px;
      height: 17px;
      position: absolute;
      left: 34px;
      bottom: 7px;
      content: "";
      background: #f3ca00;
    }
    .idea_block2 {
      display: block;
      width: 28px;
      height: 28px;
      position: absolute;
      left: 0;
      bottom: -12px;
      content: "";
      background: #f3ca00;
    }
    .idea_line1 {
      width: 70px;
      height: 3px;
      position: absolute;
      display: block;
      right: 0;
      top: -7px;
      bottom: -12px;
      content: "";
      background-color: #f3ca00;
    }
    .idea_line2 {
      width: 3px;
      height: 70px;
      position: absolute;
      display: block;
      right: 13px;
      top: -20px;
      content: "";
      background-color: #f3ca00;
    }
    .ideacon {
      width: 50%;
      display: inline-block;
      border-right: 1px solid #cccccc;
      box-sizing: border-box;
      &:last-child {
        border: none;
      }
      .idea_ico {
        width: 86px;
        height: 86px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }

      .idea_t {
        font-size: 24px;
        color: #333;
        font-weight: bold;
        text-align: center;
        margin-top: 20px;
      }

      .idea_p {
        font-size: 18px;
        color: #666666;
        margin-top: 15px;
        text-align: center;
      }
    }
  }

  .partners {
    word-spacing: -1px;
    font-size: 0;
    word-spacing: -1px;
    margin-bottom: 5px;
    li {
      width: 195px;
      margin-right: 55px;
      margin-bottom: 35px;
      display: inline-block;
      &:nth-child(5n + 5) {
        margin-right: 0;
      }
      img {
        width: 100%;
      }
    }
  }
}
</style>
