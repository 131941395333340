<template>
  <!-- 企业文化 -->
  <div class="contain">
    <!-- <div class="noticetit marginT40">企业文化</div>
    <div class="culturebg marginT20">
      <ul class="mainBodyA culturebg_ul">
        <li>
          <div class="culturebg_ul_tit fontsize24">共赢</div>
          <div class="culturebg_ul_ftit fontsize16 marginT20">
            团结有爱 互帮互助
          </div>
          <div class="culturebg_ul_img  marginT30">
            <img src="../../assets/images/company/culture/gongying.png" />
          </div>
        </li>
        <li>
          <div class="culturebg_ul_tit fontsize24">创新</div>
          <div class="culturebg_ul_ftit fontsize16 marginT20">
            团结有爱 互帮互助
          </div>
          <div class="culturebg_ul_img  marginT30">
            <img src="../../assets/images/company/culture/gongying.png" />
          </div>
        </li>
        <li>
          <div class="culturebg_ul_tit fontsize24">敬业</div>
          <div class="culturebg_ul_ftit fontsize16 marginT20">
            团结有爱 互帮互助
          </div>
          <div class="culturebg_ul_img  marginT30">
            <img src="../../assets/images/company/culture/gongying.png" />
          </div>
        </li>
      </ul>
    </div>-->
    <div class="mainBodyA wow fadeInUp animated">
      <div class="noticetit marginT40">员工风采</div>
      <div class="swiper-container" id="swiperfc">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <ul class="culturem_pic marginT20">
              <li v-for="(item, index) in staffPresenceImage" :key="index">
                <div class="culturem_pic_img">
                  <img
                    :src="$store.state.baseUrl + item.imageUrl"
                    :large="$store.state.baseUrl + item.imageUrl"
                    :preview="item.imageName"
                    :preview-text="item.imageName"
                    :alt="item.imageName"
                  />
                </div>
                <div class="culture_txt fontsize16">
                  {{ item.imageName }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pagecon">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="PageSize"
          :total="Total"
          @size-change="getPage"
          @current-change="getPage"
        ></el-pagination>
      </div>
    </div>
    <!-- 图片预览 -->
    <!-- <el-dialog
      center
      :title="dialogImgObj.imageName"
      :visible.sync="dialogTableVisible"
    >
      <img
        class="dialogImg"
        :src="$store.state.baseUrl + dialogImgObj.imageUrl"
        :alt="dialogImgObj.imageName"
      />
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      // dialogTableVisible: false,
      // dialogImgObj: {},
      // 企业文化数据
      staffPresenceobj: {},
      staffPresenceImage: [
        // {
        //     imageUrl: require("../../assets/images/company/culture/fengcai1.png"),
        //     imageName: "案场销售礼仪培训",
        // },
        // {
        //     imageUrl: require("../../assets/images/company/culture/fengcai2.png"),
        //     imageName: "V谷形象岗",
        // },
        // {
        //     imageUrl: require("../../assets/images/company/culture/fengcai3.png"),
        //     imageName: "礼仪客服进行活动指引",
        // },
      ],
      Total: 1, // 总数
      PageNo: 1, // 当前页
      PageSize: 6, // 每页显示条数
    };
  },
  mounted() {
    this.getImageManageList();
    new this.$wow.WOW({
      live: false,
      offset: 0,
    }).init();
  },
  methods: {
    // // 图片预览
    // OnChangeDialogImgObj(item) {
    //   // console.log(item);
    //   // 初始化
    //   this.dialogImgObj = {};
    //   this.dialogImgObj = item;
    //   this.$nextTick(() => {
    //     this.dialogTableVisible = true;
    //   });
    // },
    // 获取页面数据
    async getPage(page) {
      // 获取第 page 页数据
      // console.log(page);
      this.PageNo = page;
      this.getImageManageList();
    },
    // async getAboutusEnterpriseCulture() {
    //     let res = await this.$request.getAboutusEnterpriseCulture({
    //         staffPresenceColumnId: 47
    //     });
    //     if (res.code === 200) {
    //         this.staffPresenceImage = res;
    //         this.Total = res.total;
    //     }
    // },
    async getImageManageList() {
      let res = await this.$request.getImageManageList({
        pageNo: this.PageNo,
        pageSize: this.PageSize,
        columnType: 47,
      });
      if (res.code === 200) {
        this.staffPresenceImage = res.rows;
        this.Total = res.total;
        this.$previewRefresh();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogImg {
  min-width: 546px;
}
.contain {
  margin-bottom: 20px;
}
/*企业文化*/
.culturebg {
  background: #f4f4f4;
}

.culturebg_ul {
  padding: 3.13vw 0;
  word-spacing: -0.05vw;
  font-size: 0;
  word-spacing: -0.05vw;
  color: #333333;
}

.culturebg_ul > li {
  display: inline-block;
  background: #ffffff;
  text-align: center;
  margin-right: 1.56vw;
}

.culturebg_ul > li:last-child {
  margin-right: 0;
}

.culturebg_ul_tit {
  padding-top: 1.56vw;
  position: relative;
}

.culturebg_ul_tit::after {
  display: block;
  content: "";
  width: 1.98vw;
  height: 0.1vw;
  background-color: #f3ca00;
  position: absolute;
  bottom: -0.26vw;
  left: 50%;
  margin-left: -0.99vw;
}

.culturem_pic {
  word-spacing: -0.05vw;
  font-size: 0;
  word-spacing: -0.05vw;
  /* margin-bottom: 2.08vw; */
  li {
    width: 19.79vw;
    display: inline-block;
    margin: 0 1.56vw 1.56vw 0;
    overflow: hidden;
    img {
      width: 100%;
      transition: 0.6s ease-in-out;
      position: relative;
      z-index: 3;
    }
    .culture_txt {
      width: 100%;
      height: 3.13vw;
      line-height: 3.13vw;
      text-align: center;
      color: #333333;
      background: #f4f4f4;
      position: relative;
      z-index: 6;
    }
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
    &:hover {
      img {
        transform: scale(1.1, 1.1);
      }
      .culture_txt {
        color: #fff;
        background: #f3ca00;
      }
    }
  }
}

.pagecon {
  text-align: center;
  padding: 1.04vw 0;
}

@media (min-width: 1920px) {
  /*企业文化*/
  .culturebg {
    background: #f4f4f4;
  }

  .culturebg_ul {
    padding: 60px 0;
    word-spacing: -1px;
    font-size: 0;
    word-spacing: -1px;
    color: #333333;
  }

  .culturebg_ul > li {
    display: inline-block;
    background: #ffffff;
    text-align: center;
    margin-right: 30px;
  }

  .culturebg_ul > li:last-child {
    margin-right: 0;
  }

  .culturebg_ul_tit {
    padding-top: 30px;
    position: relative;
  }

  .culturebg_ul_tit::after {
    display: block;
    content: "";
    width: 38px;
    height: 2px;
    background-color: #f3ca00;
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -19px;
  }

  .culturem_pic {
    word-spacing: -1px;
    font-size: 0;
    word-spacing: -1px;
    /* margin-bottom: 40px; */
    li {
      width: 380px;
      display: inline-block;
      margin: 0 30px 30px 0;
      overflow: hidden;
      img {
        width: 100%;
        transition: 0.6s ease-in-out;
        position: relative;
        z-index: 3;
      }
      .culture_txt {
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #333333;
        background: #f4f4f4;
        position: relative;
        z-index: 6;
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      &:hover {
        img {
          transform: scale(1.1, 1.1);
        }
        .culture_txt {
          color: #fff;
          background: #f3ca00;
        }
      }
    }
  }

  .pagecon {
    text-align: center;
    padding: 20px 0;
  }
}
.pagecon /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f3ca00;
  color: #fff;
}

.pagecon /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #f3ca00;
}
</style>
