<template>
  <!-- 大事件 -->
  <div class="Memorabilia">
    <div class="mainBodyA">
      <div class="wow fadeInUp animated">
        <div class="noticetit marginT40">企业宣传片</div>
        <div class="videobg_con clear">
          <div class="video_cont marginT20 fl">
            <div
              v-show="!isShowVideo"
              class="videobg"
              :style="{
                'background-image': 'url(' + videobg + ')',
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
              }"
            >
              <div class="video_btn" v-if="videoSrc" @click="OnShowVideo">
                <img src="../../assets/images/company/bstory/bofang.png" />
              </div>
            </div>
            <div class="video-box" v-show="isShowVideo">
              <div class="player">
                <video
                  controls
                  ref="playervideo"
                  preload="auto"
                  id="playervideo"
                  :src="videoSrc"
                ></video>
              </div>
            </div>
          </div>
          <div
            class="video_ul marginT20 fl"
            v-if="resObj.enterpriseVideos.length > 0"
          >
            <swiperCpt
              class="swiperVideoList video_list"
              ref="VideoListSwiper"
              :slidesPerView="1"
              direction="vertical"
              :loop="false"
              sid="VideoListSwiper"
              @onSlideChangeEnd="onSlideChangeEnd"
              v-if="videosList.length > 0"
            >
              <div
                class="swiper-slide"
                v-for="(item1, index1) in videosList"
                :key="'VideoList' + index1"
              >
                <div
                  class="VideoItem"
                  v-for="(item, index) in item1"
                  :key="'VideoList' + index"
                  @click="OnUpdataVideo(item)"
                >
                  <div
                    class="video_list_ulImg"
                    :style="{
                      'background-image':
                        'url(' + $store.state.baseUrl + item.imageUrl + ')',
                      'background-repeat': 'no-repeat',
                      'background-size': 'cover',
                    }"
                  >
                    <!-- <img src="../../assets/images/company/bstory/shipin.png" /> -->
                  </div>
                  <div class="video_list_t textSl1">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </swiperCpt>
            <!-- 分页 -->

            <div class="space-next-prev clear">
              <el-button
                :disabled="isBeginning || videosList.length <= 1"
                class="s-btn btn-prev"
                @click="$refs.VideoListSwiper.swiperSl.swipePrev()"
              >
                上一页
              </el-button>
              <el-button
                :disabled="isEnd || videosList.length <= 1"
                class="s-btn btn-next"
                @click="$refs.VideoListSwiper.swiperSl.swipeNext()"
              >
                下一页
              </el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="wow fadeInUp animated">
        <div class="noticetit marginT40">企业大事记</div>
        <div class="bscon marginT20">
          <div class="bscon_jt"></div>
          <div class="bscon_main">
            <div class="bscon_main_co">
              <swiperCpt
                class="swiperDsj Dsj_list"
                ref="DsjSwiper"
                :slidesPerView="3"
                :loop="false"
                sid="DsjSwiper"
                v-if="memorabiliasList.length > 0"
              >
                <div
                  class="swiper-slide"
                  v-for="(item, index) in memorabiliasList"
                  :key="'Dsj' + index"
                >
                  <div class="bscon_main_btit">
                    {{ item.year }}
                  </div>
                  <div class="content">
                    <div class="content_text" v-html="item.content"></div>
                  </div>
                </div>
              </swiperCpt>
            </div>
            <div
              class="bscon_main_left"
              @click="$refs.DsjSwiper.swiperSl.swipePrev()"
            ></div>
            <div
              class="bscon_main_right"
              @click="$refs.DsjSwiper.swiperSl.swipeNext()"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频播放 -->
    <!-- <div class="video_model" v-if="isShowVideo"></div>
        <div class="video_con" v-show="isShowVideo">
            <div class="closeVd" @click="OnPaseVideo"></div>
            <div class="video-player">
                <div class="player">
                    <video
                        controls
                        ref="playervideo"
                        preload="auto"
                        id="playervideo"
                        :src="videoSrc"
                    ></video>
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>
import swiperCpt from "@/components/common/swiperCpt.vue";

export default {
  data() {
    return {
      videobg: require("../../assets/images/company/bstory/moren.png"),
      isShowVideo: false,
      videoSrc: "",
      memorabiliaObj: {
        enterpriseVideos: [
          {
            title: "开业典礼",
            imageUrl: require("../../assets/images/company/bstory/shipin.png"),
            videoUrl: "",
          },
        ],
        memorabilias: [
          {
            year: "2020年4月",
            content:
              "广州工控印发《关于印发整合集团物业管理资源组建广州工控服务管理有限公司工作方案的通知》",
          },
          {
            year: "2020年8月",
            content: "工控服务本部员工廉洁谈话会议召开",
          },
          {
            year: "2020年12月",
            content: "工控服务第一次第一次全体党员大会和全体委员会议召开",
          },
        ],
      },
      resObj: {
        enterpriseVideos: [],
        memorabilias: [],
      },
      // swiper是否还有上下页
      isBeginning: true,
      isEnd: false,
    };
  },
  computed: {
    videosList() {
      let arr = this.resObj.enterpriseVideos || [],
        newArr = [];
      for (var i = 0; i < arr.length; i += 3) {
        newArr.push(arr.slice(i, i + 3));
      }
      return newArr;
    },
    memorabiliasList(){
      return this.resObj.memorabilias.reverse()
    }
  },
  mounted() {
    // 获取大事记数据
    this.getAboutusMemorabilia();
    new this.$wow.WOW({
      live: false,
      offset: 0,
    }).init();
  },
  methods: {
    // swiper切换
    onSlideChangeEnd() {
      //   console.log(this.$refs.VideoListSwiper.swiperSl, "实力");
      this.isBeginning = this.$refs.VideoListSwiper.swiperSl.isBeginning;
      this.isEnd = this.$refs.VideoListSwiper.swiperSl.isEnd;
      // console.log(this.$refs.VideoListSwiper.swiperSl.isBeginning);
      // console.log(this.$refs.VideoListSwiper.swiperSl.isEnd);
    },
    // 获取页面数据
    async getAboutusMemorabilia() {
      let resData = await this.$request.getAboutusMemorabilia({
        enterpriseId: "e230b713-95c4-40c0-89de-f73d645e25a0",
      });
      if (resData.code === 200) {
        // console.log("resData", resData);
        this.resObj = resData;
        this.$nextTick(() => {
          this.$refs.VideoListSwiper.swiperSl.reInit();
        });

        // 初始化封面图
        this.videobg =
          resData.enterpriseVideos.length > 0
            ? this.$store.state.baseUrl + resData.enterpriseVideos[0].imageUrl
            : this.videobg;
        // 初始化视频
        this.videoSrc =
          this.$store.state.baseUrl + resData.enterpriseVideos[0].videoUrl;
      }
    },
    // 更换视频地址
    OnUpdataVideo(val) {
      if (!!val.videoUrl && val) {
        // 更新视频链接和封面图
        this.videoSrc = this.$store.state.baseUrl + val.videoUrl;
        this.videobg = this.$store.state.baseUrl + val.imageUrl;
        // let _that = this;
        // this.$nextTick(() => {
        //     _that.OnShowVideo();
        // });
      } else {
        this.$message.error("抱歉！该视频已下架");
      }
    },
    //播放视频
    OnShowVideo() {
      this.isShowVideo = true;
      this.$refs.playervideo.play();
      //   console.log(v);
    },
    // 暂停视频
    OnPaseVideo() {
      this.isShowVideo = false;
      this.$refs.playervideo.pause();
    },
  },
  components: {
    swiperCpt,
  },
};
</script>

<style lang="scss" scoped>
/*大事件*/

.bscon_jt {
  margin-top: 5.73vw;
  width: 62.5vw;
  height: 2.5vw;
  background: url("../../assets/images/company/bstory/jiantou.png") center right
    no-repeat;
  background-size: cover;
}

.bscon_main {
  position: relative;
  .bscon_main_left {
    display: block;
    width: 2.19vw;
    height: 3.65vw;
    background: url("../../assets/images/company/bstory/prev.png") center
      no-repeat;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    top: 7.03vw;
    left: 0;
    &:hover {
      background: url("../../assets/images/company/bstory/prev_on.png") center
        no-repeat;
      background-size: cover;
    }
  }

  .bscon_main_right {
    display: block;
    width: 2.19vw;
    height: 3.65vw;
    background: url("../../assets/images/company/bstory/next.png") center
      no-repeat;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    top: 7.03vw;
    right: 0;
    &:hover {
      background: url("../../assets/images/company/bstory/next_on.png") center
        no-repeat;
      background-size: cover;
    }
  }
  .bscon_main_co {
    width: 46.88vw;
    height: 17.19vw;
    margin: -6.25vw auto 2.08vw auto;
    .Dsj_list {
      width: 46.88vw;
      height: 17.19vw;
      .swiper-slide {
        width: 12.76vw !important;
        display: inline-block;
        font-size: 0.83vw;
        margin: 0 3.13vw 0 0;
        cursor: pointer;
        &:last-child {
          margin: 0 0 0 0;
        }
        &:hover .content {
          background-color: #f3ca00;
          color: #ffffff;
        }
        .content {
          height: 13.44vw !important;
          text-indent: 1.67vw;
          background-color: #ffffff;
          box-shadow: 0vw 0.05vw 0.26vw 0.05vw rgba(0, 0, 0, 0.08);
          padding: 2.6vw 1.04vw 1.04vw 1.04vw;
          box-sizing: border-box;
          margin-top: -1.56vw;
          z-index: 0;
          line-height: 1.46vw;
          &:hover {
            background-color: #f3ca00;
            color: #fff;
          }
          .content_text {
            padding: 0.26vw 0 0 0;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box; //作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
            -webkit-line-clamp: 6; //显示的行
            /deep/ p {
              text-align: justify;
              word-break: normal;
            }
          }
        }
      }

      .bscon_main_btit {
        width: 8.65vw;
        height: 2.97vw;
        line-height: 2.97vw;
        text-align: center;
        color: #f3ca00;
        font-size: 1.04vw;
        font-weight: bold;
        margin: 0 auto;
        background: url(../../assets/images/company/bstory/nianfen.png)
          no-repeat;
        background-size: 100%;
        z-index: 1;
        position: relative;
      }
    }
  }
}

.video_model {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.video_con {
  width: 60%;
  max-height: 80%;
  top: 50%;
  z-index: 1001;
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  .closeVd {
    width: 2.08vw;
    height: 2.08vw;
    position: absolute;
    top: 0;
    right: -3.13vw;
    z-index: 66;
    cursor: pointer;
    background: url("../../assets/images/common/close.png") no-repeat;
    background-size: 100%;
    &:hover {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    -webkit-transition: transform ease-in-out 0.3s;
    -moz-transition: transform ease-in-out 0.3s;
    -ms-transition: transform ease-in-out 0.3s;
    -o-transition: transform ease-in-out 0.3s;
    transition: transform ease-in-out 0.3s;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
}

.video-player {
  width: 100%;
  max-height: 100%;
}

.player {
  width: 100%;
  background: #000;
}

.video-player video {
  width: 100%;
  max-height: 41.67vw;
}
.videobg_con {
  .video_cont {
    width: 50.52vw;
    height: 28.44vw;
    position: relative;
    .video-box {
      width: 100%;
      height: 100%;
      video {
        width: 100%;
        height: 28.44vw;
      }
    }
  }
  .videobg {
    width: 50.52vw;
    height: 28.44vw;
    // background: url(../../assets/images/company/bstory/shipin.png) no-repeat;
    // background-size: auto 100%;
    position: relative;
    .video_btn {
      width: 2.4vw;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1.2vw;
      margin-top: -1.2vw;
      cursor: pointer;
    }
  }
  .video_ul {
    width: 11.15vw;
    position: relative;
    margin: 0 0 0.52vw 0.73vw;
    .video_list {
      width: 11.15vw;
      height: 26.04vw;
      .swiper-slide {
        .VideoItem {
          cursor: pointer;
          &:hover {
            .video_list_t {
              color: #ffffff;
              background-color: #f3ca00;
            }
          }
          .video_list_ulImg {
            width: 11.15vw;
            height: 6.35vw;
          }
          .video_list_t {
            box-sizing: border-box;
            padding: 0 0.52vw;
            width: 100%;
            text-align: center;
            height: 1.93vw;
            line-height: 1.93vw;
            font-size: 0.83vw;
            background-color: #eeeeee;
          }
        }
      }
    }

    .space-next-prev {
      width: 11.15vw;
      padding: 0.21vw 0 0 0;
      text-align: center;
      .s-btn {
        // display: inline-block;
        width: 3.54vw;
        height: 1.67vw;
        line-height: 1.67vw;
        text-align: center;
        font-size: 0.83vw;
        padding: 0;
        border-radius: 0;
        background-color: #eeeeee;
        color: #666666;
        // cursor: pointer;
        &.btn-next {
          margin: 0 0 0 0.52vw;
        }
        &:hover {
          color: #ffffff;
          background-color: #f3ca00;
          &.is-disabled {
            color: #ffffff;
            background-color: #999;
          }
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  /*大事件*/

  .bscon_jt {
    margin-top: 110px;
    width: 1200px;
    height: 45px;
    background: url("../../assets/images/company/bstory/jiantou.png") center
      right no-repeat;
    background-size: cover;
  }

  .bscon_main {
    position: relative;
    .bscon_main_left {
      display: block;
      width: 42px;
      height: 70px;
      background: url("../../assets/images/company/bstory/prev.png") center
        no-repeat;
      background-size: cover;
      cursor: pointer;
      position: absolute;
      top: 135px;
      left: 0;
      &:hover {
        background: url("../../assets/images/company/bstory/prev_on.png") center
          no-repeat;
        background-size: cover;
      }
    }

    .bscon_main_right {
      display: block;
      width: 42px;
      height: 70px;
      background: url("../../assets/images/company/bstory/next.png") center
        no-repeat;
      background-size: cover;
      cursor: pointer;
      position: absolute;
      top: 135px;
      right: 0;
      &:hover {
        background: url("../../assets/images/company/bstory/next_on.png") center
          no-repeat;
        background-size: cover;
      }
    }
    .bscon_main_co {
      width: 900px;
      height: 286px;
      margin: -120px auto 40px auto;
      .Dsj_list {
        width: 900px;
        height: 330px;
        text-align: center;
        .swiper-slide {
          width: 245px !important;
          display: inline-block;
          height: 330px;
          font-size: 16px;
          margin: 0 60px 0 0;
          cursor: pointer;
          &:last-child {
            margin: 0 0 0 0;
          }
          &:hover .content {
            background-color: #f3ca00;
            color: #ffffff;
          }
          .content {
            height: 258px !important;
            text-indent: 32px;
            background-color: #ffffff;
            box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.08);
            padding: 50px 20px 20px 20px;
            box-sizing: border-box;
            margin-top: -30px;
            z-index: 0;
            line-height: 28px;
            &:hover {
              background-color: #f3ca00;
              color: #fff;
            }
            .content_text {
              padding: 5px 0 0 0;
              position: relative;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              display: -webkit-box; //作为弹性伸缩盒子模型显示。
              -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
              -webkit-line-clamp: 6; //显示的行
              /deep/ p {
                text-align: justify;
                word-break: normal;
              }
            }
          }
        }

        .bscon_main_btit {
          width: 166px;
          height: 57px;
          line-height: 57px;
          text-align: center;
          color: #f3ca00;
          font-size: 20px;
          font-weight: bold;
          margin: 0 auto;
          background: url(../../assets/images/company/bstory/nianfen.png)
            no-repeat;
          background-size: 100%;
          z-index: 1;
          position: relative;
        }
      }
    }
  }

  .video_model {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .video_con {
    width: 60%;
    max-height: 80%;
    top: 50%;
    z-index: 1001;
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    .closeVd {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: -60px;
      z-index: 66;
      cursor: pointer;
      background: url("../../assets/images/common/close.png") no-repeat;
      background-size: 100%;
      &:hover {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
      -webkit-transition: transform ease-in-out 0.3s;
      -moz-transition: transform ease-in-out 0.3s;
      -ms-transition: transform ease-in-out 0.3s;
      -o-transition: transform ease-in-out 0.3s;
      transition: transform ease-in-out 0.3s;
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }
  }

  .video-player {
    width: 100%;
    max-height: 100%;
  }

  .player {
    background: #000;
  }

  .video-player video {
    width: 100%;
    max-height: 800px;
  }
  .videobg_con {
    position: relative;
    .video_cont {
      width: 970px;
      height: 546px;
      position: relative;
      .video-box {
        width: 100%;
        height: 100%;
        video {
          width: 100%;
          height: 546px;
        }
      }
    }
    .videobg {
      width: 970px;
      height: 546px;
      background: url(../../assets/images/company/bstory/shipin.png) no-repeat;
      background-size: auto 100%;
      position: relative;
      .video_btn {
        width: 46px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -23px;
        margin-top: -23px;
        cursor: pointer;
      }
    }
    .video_ul {
      width: 214px;
      position: relative;
      margin: 0 0 10px 14px;
      .video_list {
        width: 214px;
        height: 500px;
        .swiper-slide {
          .VideoItem {
            cursor: pointer;
            &:hover {
              .video_list_t {
                color: #ffffff;
                background-color: #f3ca00;
              }
            }
            .video_list_ulImg {
              width: 214px;
              height: 122px;
            }
            .video_list_t {
              box-sizing: border-box;
              padding: 0 10px;
              width: 100%;
              text-align: center;
              height: 37px;
              line-height: 37px;
              font-size: 16px;
              background-color: #eeeeee;
              .textSl1 {
                height: 20px;
              }
            }
          }
        }
      }

      .space-next-prev {
        width: 214px;
        padding: 4px 0 0 0;
        text-align: center;
        .s-btn {
          display: inline-block;
          width: 68px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-size: 16px;
          background-color: #eeeeee;
          color: #666666;
          // cursor: pointer;
          &.btn-next {
            margin: 0 0 0 10px;
          }
          &:hover {
            color: #ffffff;
            background-color: #f3ca00;
            &.is-disabled {
              color: #ffffff;
              background-color: #999;
            }
          }
        }
      }
    }
  }
}
</style>
