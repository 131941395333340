var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Memorabilia"},[_c('div',{staticClass:"mainBodyA"},[_c('div',{staticClass:"wow fadeInUp animated"},[_c('div',{staticClass:"noticetit marginT40"},[_vm._v("企业宣传片")]),_c('div',{staticClass:"videobg_con clear"},[_c('div',{staticClass:"video_cont marginT20 fl"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowVideo),expression:"!isShowVideo"}],staticClass:"videobg",style:({
              'background-image': 'url(' + _vm.videobg + ')',
              'background-repeat': 'no-repeat',
              'background-size': 'cover',
            })},[(_vm.videoSrc)?_c('div',{staticClass:"video_btn",on:{"click":_vm.OnShowVideo}},[_c('img',{attrs:{"src":require("../../assets/images/company/bstory/bofang.png")}})]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowVideo),expression:"isShowVideo"}],staticClass:"video-box"},[_c('div',{staticClass:"player"},[_c('video',{ref:"playervideo",attrs:{"controls":"","preload":"auto","id":"playervideo","src":_vm.videoSrc}})])])]),(_vm.resObj.enterpriseVideos.length > 0)?_c('div',{staticClass:"video_ul marginT20 fl"},[(_vm.videosList.length > 0)?_c('swiperCpt',{ref:"VideoListSwiper",staticClass:"swiperVideoList video_list",attrs:{"slidesPerView":1,"direction":"vertical","loop":false,"sid":"VideoListSwiper"},on:{"onSlideChangeEnd":_vm.onSlideChangeEnd}},_vm._l((_vm.videosList),function(item1,index1){return _c('div',{key:'VideoList' + index1,staticClass:"swiper-slide"},_vm._l((item1),function(item,index){return _c('div',{key:'VideoList' + index,staticClass:"VideoItem",on:{"click":function($event){return _vm.OnUpdataVideo(item)}}},[_c('div',{staticClass:"video_list_ulImg",style:({
                    'background-image':
                      'url(' + _vm.$store.state.baseUrl + item.imageUrl + ')',
                    'background-repeat': 'no-repeat',
                    'background-size': 'cover',
                  })}),_c('div',{staticClass:"video_list_t textSl1"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)}),0):_vm._e(),_c('div',{staticClass:"space-next-prev clear"},[_c('el-button',{staticClass:"s-btn btn-prev",attrs:{"disabled":_vm.isBeginning || _vm.videosList.length <= 1},on:{"click":function($event){return _vm.$refs.VideoListSwiper.swiperSl.swipePrev()}}},[_vm._v(" 上一页 ")]),_c('el-button',{staticClass:"s-btn btn-next",attrs:{"disabled":_vm.isEnd || _vm.videosList.length <= 1},on:{"click":function($event){return _vm.$refs.VideoListSwiper.swiperSl.swipeNext()}}},[_vm._v(" 下一页 ")])],1)],1):_vm._e()])]),_c('div',{staticClass:"wow fadeInUp animated"},[_c('div',{staticClass:"noticetit marginT40"},[_vm._v("企业大事记")]),_c('div',{staticClass:"bscon marginT20"},[_c('div',{staticClass:"bscon_jt"}),_c('div',{staticClass:"bscon_main"},[_c('div',{staticClass:"bscon_main_co"},[(_vm.memorabiliasList.length > 0)?_c('swiperCpt',{ref:"DsjSwiper",staticClass:"swiperDsj Dsj_list",attrs:{"slidesPerView":3,"loop":false,"sid":"DsjSwiper"}},_vm._l((_vm.memorabiliasList),function(item,index){return _c('div',{key:'Dsj' + index,staticClass:"swiper-slide"},[_c('div',{staticClass:"bscon_main_btit"},[_vm._v(" "+_vm._s(item.year)+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content_text",domProps:{"innerHTML":_vm._s(item.content)}})])])}),0):_vm._e()],1),_c('div',{staticClass:"bscon_main_left",on:{"click":function($event){return _vm.$refs.DsjSwiper.swiperSl.swipePrev()}}}),_c('div',{staticClass:"bscon_main_right",on:{"click":function($event){return _vm.$refs.DsjSwiper.swiperSl.swipeNext()}}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }