<template>
  <!-- 专业服务 -->
  <div class="ProfessionalService">
    <div class="mainBodyA wow fadeInUp animated">
      <div class="noticetit marginT40">荣誉资质</div>
      <div class="companycon">
        <ul class="server_con marginT40">
          <swiperCpt
            class="swiperhor"
            ref="honorswiper"
            :slidesPerView="3"
            :delay="3000"
            :loop="false"
            sid="honorswiper"
            :autoplay="resObj.honorImage.length > 3 ? true : false"
            v-if="resObj.honorImage.length > 0"
          >
            <!-- :loop="resObj.honorImage.length > 3 ? true : false" -->
            <div
              class="swiper-slide"
              v-for="(item, index) in resObj.honorImage"
              :key="'honor' + index"
            >
              <div class="server_img">
                <img
                  :src="$store.state.baseUrl + item.imageUrl"
                  :alt="'honor' + index"
                  :large="$store.state.baseUrl + item.imageUrl"
                  :preview="item.imageName"
                  :preview-text="item.imageName"
                />
              </div>
              <div class="server_txt">
                <div class="server_txt_tit color333">
                  {{ item.imageName }}
                </div>
                <div class="server_txt_time marginT10">
                  {{ item.imageDesc }}
                </div>
              </div>
            </div>
          </swiperCpt>
        </ul>
        <div class="serverTxt marginT20 fontsize16 color333">
          <div class="honor" v-html="resObj.honor"></div>
          <div
            class="qualification marginT10"
            v-html="resObj.qualification"
          ></div>
        </div>
      </div>
    </div>
    <div class="serverbg wow fadeInUp animated">
      <div class="mainBodyA">
        <swiperCpt
          class="swiperQualification"
          ref="QualificationSwiper"
          :slidesPerView="6"
          :delay="3000"
          :loop="false"
          sid="QualificationSwiper"
          :autoplay="resObj.qualificationImage.length > 6 ? true : false"
          v-if="resObj.qualificationImage.length > 0"
        >
          <!-- :loop="resObj.qualificationImage.length > 6 ? true : false" -->
          <div
            class="swiper-slide"
            v-for="(item, index) in resObj.qualificationImage"
            :key="'Qualification' + index"
          >
            <div class="QualificationImg">
              <img
                :src="$store.state.baseUrl + item.imageUrl"
                :alt="'QualificationImg' + index"
                :large="$store.state.baseUrl + item.imageUrl"
                :preview="item.imageName"
                :preview-text="item.imageName"
              />
            </div>
          </div>
        </swiperCpt>
      </div>
    </div>

    <div ref="memberContent" class="mainBodyA wow fadeInUp animated">
      <!-- 专业团队 -->
      <div class="noticetit marginT40">专业团队</div>
      <ul class="zynum marginT20">
        <li v-for="(item, index) in resObj.teams" :key="index">
          <div class="colorf3c fontsize16">
            <span class="fontsize36 num1">
              <animate-number
                ref="myNum"
                from="1"
                mode="manual"
                :to="item.year"
              ></animate-number></span
            >{{ item.content }}
          </div>
          <div class="color333 marginT5 fontsize16">
            {{ item.title }}
          </div>
        </li>
      </ul>
      <div class="professional professional1">
        <div class="professional_con professional_con1">
          <div v-html="resObj.teamIntroduce"></div>
          <div class="jiantou jiantouRight"></div>
        </div>
        <swiperCpt
          class="swiperProfessional professional_img"
          ref="professionalSwiper"
          :slidesPerView="2"
          :delay="3000"
          sid="professionalSwiper"
          :autoplay="resObj.teamImage.length > 2 ? true : false"
          :loop="resObj.teamImage.length > 2 ? true : false"
          v-if="resObj.teamImage.length > 0"
        >
          <div
            class="swiper-slide"
            v-for="(item, index) in resObj.teamImage"
            :key="index"
          >
            <div class="professional_img_con">
              <div class="professional_img_conimg">
                <img
                  :src="$store.state.baseUrl + item.imageUrl"
                  :alt="'professional' + index"
                />
              </div>
              <div class="professional_img_contxt">
                {{ item.imageName }}
              </div>
            </div>
          </div>
        </swiperCpt>
      </div>
      <!-- 责任与担当 -->
      <div class="noticetit marginT40">责任与担当</div>
      <div class="professional professional2 marginT80">
        <swiperCpt
          class="swiperProfessional professional_img"
          ref="professionalSwiper1"
          :slidesPerView="3"
          :delay="3000"
          sid="professionalSwiper1"
          :autoplay="resObj.responsibilityImage.length > 3 ? true : false"
          :loop="resObj.responsibilityImage.length > 3 ? true : false"
          v-if="resObj.responsibilityImage.length > 0"
        >
          <div
            class="swiper-slide"
            v-for="(item, index) in resObj.responsibilityImage"
            :key="index"
          >
            <div class="professional_img_con blame">
              <div class="professional_img_conimg">
                <img
                  :src="$store.state.baseUrl + item.imageUrl"
                  :alt="'professional' + index"
                />
              </div>
              <div class="professional_img_contxt">
                {{ item.imageName }}
              </div>
            </div>
          </div>
        </swiperCpt>
        <div class="professional_con blameImg">
          <div v-html="resObj.responsibility"></div>
          <div class="jiantou jiantouLeft"></div>
        </div>
      </div>
    </div>
    <!-- 图片预览 -->
    <!-- <el-dialog
      center
      :title="dialogImgObj.imageName"
      :visible.sync="dialogTableVisible"
    >
      <img
        class="dialogImg"
        :src="$store.state.baseUrl + dialogImgObj.imageUrl"
        :alt="dialogImgObj.imageName"
      />
    </el-dialog> -->
  </div>
</template>

<script>
import swiperCpt from "@/components/common/swiperCpt.vue";

export default {
  data() {
    return {
      showTip: false,
      resObj: {
        honorImage: [],
        qualificationImage: [],
        teamImage: [],
        responsibilityImage: [],
      },
    };
  },
  mounted() {
    // 获取页面数据
    this.getAboutusProfessional();
    // 滑动呈现动画
    new this.$wow.WOW({
      live: false,
      offset: 0,
    }).init();
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // // 图片预览
    // OnChangeDialogImgObj(item) {
    //   // console.log(item);
    //   // 初始化
    //   this.dialogImgObj = {};
    //   this.dialogImgObj = item;
    //   this.$nextTick(() => {
    //     this.dialogTableVisible = true;
    //   });
    // },
    async getAboutusProfessional() {
      let resData = await this.$request.getAboutusProfessional({
        enterpriseId: "e230b713-95c4-40c0-89de-f73d645e25a0",
        honorColumnId: 44,
        qualificationColumnId: 45,
        teamColumnId: 49,
        responsibilityColumnId: 50,
      });
      if (resData.code === 200) {
        // console.log("resData", resData);
        // this.resObj = resData;
        this.resObj = resData;
        // console.log(this.$refs.honorswiper)
        // this.$refs.honorswiper.mySwiper.init();
      }
    },
    handleScroll() {
      let scrollHeight =
        document.documentElement.scrollTop || document.body.scrollTop; //滚动高度
      let boxHeight = 0;
      boxHeight = this.$refs.memberContent.clientHeight; //盒子高度
      if (boxHeight - scrollHeight <= 20) {
        this.showTip = true;
      } else {
        this.showTip = false;
      }
    },
  },
  watch: {
    showTip(newval, oldval) {
      if (newval && this.$refs.myNum) {
        this.$refs.myNum.map((item) => {
          item.start();
        });
      }
    },
  },
  components: { swiperCpt },
};
</script>

<style lang="scss" scoped>
/*企业文化*/
.companycon {
  padding: 0 0 2.08vw 0;
  .server_con {
    word-spacing: -0.05vw;
    font-size: 0;
    word-spacing: -0.05vw;
    .swiper-slide {
      cursor: pointer;
      // width: 19.79vw;
      // display: inline-block;
      // margin-right: 1.46vw;
      &:last-child {
        margin-right: 0;
      }
      &:hover .server_txt {
        background: #f3ca00;
        color: #ffffff;
        .server_txt_tit {
          color: #ffffff !important;
        }
        .server_txt_time {
          color: #ffffff !important;
        }
      }
    }
    .server_img {
      width: 19.58vw;
        height: 13.71vw;
      padding: 0.52vw;
      box-sizing: border-box;
      background: #eaeaea;
      margin: 0 0.63vw;
    }

    .server_img > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .server_txt {
      height: 4.95vw;
      width: 19.58vw;
      padding: 1.04vw 0;
      margin: 0 0.63vw;
      box-sizing: border-box;
      background: #ffffff;
      text-align: center;
      border: solid 1px #eeeeee;
      .server_txt_tit {
        color: #333333;
        font-size: 1.04vw;
      }

      .server_txt_time {
        color: #666666;
        font-size: 0.94vw;
      }
    }
  }
  .serverTxt {
    line-height: 1.56vw;
    text-indent: 1.82vw;
  }
}
.serverbg {
  background: #f4f4f4;
  padding: 1.56vw 0;
  .swiperQualification {
    .QualificationImg {
      cursor: pointer;
      padding: 0 0.52vw;
      img {
        width: 100%;
      }
    }
  }
}
.zynum {
  word-spacing: -0.05vw;
  font-size: 0;
  word-spacing: -0.05vw;
}

.zynum > li {
  width: 20%;
  text-align: center;
  display: inline-block;
}

.professional {
  font-size: 0;
  margin: 2.08vw 0 2.08vw;
  position: relative;
  &.professional1 {
    .professional_con {
      width: 18.02vw;
      padding: 2.6vw 8.65vw 0.73vw 1.77vw;
      min-height: 9.84vw;
      font-size: 0.83vw;
      background-color: #eaeaea;
      position: absolute;
      top: 0;
      left: 0;
      &::before {
        left: 0.52vw;
        top: -1.35vw;
      }
      &::after {
        left: 0;
        top: -0.52vw;
      }
    }
    .professional_img {
      margin: 0 0 0 21.56vw;
      position: relative;
      z-index: 6;
    }
  }
  &.professional2 {
    .professional_con {
      width: 17.19vw;
      min-height: 9.74vw;
      padding: 2.08vw 2.08vw 0 8.33vw;
      background-color: #393939;
      font-size: 0.83vw;
      color: #f4f4f4;
      position: absolute;
      top: -2.6vw;
      right: 0;
      &::before {
        right: 0.52vw;
        bottom: -1.77vw;
      }
      &::after {
        right: 0;
        bottom: -0.83vw;
      }
    }
    .professional_img {
      position: relative;
      z-index: 6;
    }
  }
  .professional_con {
    // width: 23.96vw;
    display: inline-block;
    margin-top: 0.52vw;
    text-indent: 1.88vw;
    line-height: 1.25vw;
    letter-spacing: 0.1vw;
    color: #666666;
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 9;
      width: 0.16vw;
      height: 3.65vw;
      background-color: #f3ca00;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 9;
      width: 3.65vw;
      height: 0.16vw;
      background-color: #f3ca00;
    }
    .jiantou {
      width: 3.44vw;
      height: 0.52vw;
      position: absolute;
      bottom: 1.04vw;
      &.jiantouLeft {
        right: 15.63vw;
        background: url("../../assets/images/common/jiantouLeft.png");
        background-size: cover;
      }
      &.jiantouRight {
        left: 16.15vw;
        background: url("../../assets/images/common/jiantouRight.png");
        background-size: cover;
      }
    }
  }
  .professional_img {
    width: 41.15vw;
    display: inline-block;
    position: relative;
    z-index: 2;
    .professional_img_con {
      margin-right: 1.3vw;
      width: 19.79vw;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
      .professional_img_conimg {
        width: 19.79vw;
        height: 13.65vw;
        overflow: hidden;
        transition: 0.6s ease-in-out;
        img {
          width: 100%;
        }
        &:hover img {
          transform: scale(1.08, 1.08);
        }
      }
    }
    .blame {
      width: 12.92vw;
      margin-right: 1.04vw;
      .professional_img_conimg {
        width: 12.92vw;
        height: 9.69vw;
        overflow: hidden;
      }
    }
  }
}

.professional_img_contxt {
  height: 2.08vw;
  line-height: 2.08vw;
  width: 100%;
  color: #333333;
  font-size: 0.83vw;
  background-color: #eaeaea;
  text-align: center;
}

.blameImg {
  width: 28.02vw;
  margin-left: -6.77vw;
  margin-top: -2.19vw;
  position: relative;
  z-index: 2;
}

.professional_img_con {
  margin-right: 1.3vw;
  width: 19.79vw;
  display: inline-block;
}

.professional_img_con:last-child {
  margin-right: 0;
}

.professional_img_conimg {
  width: 19.79vw;
  height: 13.65vw;
  overflow: hidden;
}

.professional_img_conimg:hover img {
  transform: scale(1.08, 1.08);
}

.professional_img_conimg > img {
  width: 100%;
  transition: 0.6s ease-in-out;
}

.blame > .professional_img_conimg {
  width: 12.92vw;
  height: 9.69vw;
  overflow: hidden;
}

.professional_img_contxt {
  height: 2.08vw;
  line-height: 2.08vw;
  width: 100%;
  color: #333333;
  font-size: 0.83vw;
  background-color: #eaeaea;
  text-align: center;
}

.blame {
  width: 12.92vw;
  margin-right: 1.04vw;
}

.blameImg {
  width: 28.02vw;
  margin-left: -6.77vw;
  margin-top: -2.19vw;
  position: relative;
  z-index: 1;
}

.marginT80 {
  margin-top: 4.17vw;
}
@media (min-width: 1920px) {
  /*企业文化*/
  .companycon {
    padding: 0 0 40px 0;
    .server_con {
      word-spacing: -1px;
      font-size: 0;
      word-spacing: -1px;
      .swiper-slide {
        // width: 380px;
        // display: inline-block;
        // margin-right: 28px;
        &:last-child {
          margin-right: 0;
        }
        &:hover .server_txt {
          background: #f3ca00;
          color: #ffffff;
          .server_txt_tit {
            color: #ffffff !important;
          }
          .server_txt_time {
            color: #ffffff !important;
          }
        }
      }
      .server_img {
        width: 376px;
        height: 263.19px;
        padding: 10px;
        box-sizing: border-box;
        background: #eaeaea;
        margin: 0 12px;
      }

      .server_img > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .server_txt {
        height: 95px;
        width: 376px;
        padding: 20px 0;
        margin: 0 12px;
        box-sizing: border-box;
        background: #ffffff;
        text-align: center;
        border: solid 1px #eeeeee;
        .server_txt_tit {
          color: #333333;
          font-size: 20px;
        }

        .server_txt_time {
          color: #666666;
          font-size: 18px;
        }
      }
    }
    .serverTxt {
      line-height: 30px;
      text-indent: 35px;
    }
  }
  .serverbg {
    background: #f4f4f4;
    padding: 30px 0;
    .swiperQualification {
      .QualificationImg {
        padding: 0 10px;
        img {
          width: 100%;
        }
      }
    }
  }
  .zynum {
    word-spacing: -1px;
    font-size: 0;
    word-spacing: -1px;
  }

  .zynum > li {
    width: 20%;
    text-align: center;
    display: inline-block;
  }

  .professional {
    font-size: 0;
    margin: 40px 0 40px;
    position: relative;
    &.professional1 {
      .professional_con {
        width: 346px;
        padding: 50px 166px 14px 34px;
        min-height: 189px;
        font-size: 16px;
        background-color: #eaeaea;
        position: absolute;
        top: 0;
        left: 0;
        &::before {
          left: 10px;
          top: -26px;
        }
        &::after {
          left: 0;
          top: -10px;
        }
      }
      .professional_img {
        margin: 0 0 0 414px;
        position: relative;
        z-index: 6;
      }
    }
    &.professional2 {
      .professional_con {
        width: 330px;
        min-height: 187px;
        padding: 40px 40px 0 160px;
        background-color: #393939;
        font-size: 16px;
        color: #f4f4f4;
        position: absolute;
        top: -50px;
        right: 0;
        &::before {
          right: 10px;
          bottom: -34px;
        }
        &::after {
          right: 0;
          bottom: -16px;
        }
      }
      .professional_img {
        position: relative;
        z-index: 6;
      }
    }
    .professional_con {
      // width: 460px;
      display: inline-block;
      margin-top: 10px;
      text-indent: 36px;
      line-height: 24px;
      letter-spacing: 2px;
      color: #666666;
      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 9;
        width: 3px;
        height: 70px;
        background-color: #f3ca00;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 9;
        width: 70px;
        height: 3px;
        background-color: #f3ca00;
      }
      .jiantou {
        width: 66px;
        height: 10px;
        position: absolute;
        bottom: 20px;
        &.jiantouLeft {
          right: 300px;
          background: url("../../assets/images/common/jiantouLeft.png");
        }
        &.jiantouRight {
          left: 310px;
          background: url("../../assets/images/common/jiantouRight.png");
        }
      }
    }
    .professional_img {
      width: 790px;
      display: inline-block;
      position: relative;
      z-index: 2;
      .professional_img_con {
        margin-right: 25px;
        width: 380px;
        display: inline-block;
        &:last-child {
          margin-right: 0;
        }
        .professional_img_conimg {
          width: 380px;
          height: 262px;
          overflow: hidden;
          transition: 0.6s ease-in-out;
          img {
            width: 100%;
          }
          &:hover img {
            transform: scale(1.08, 1.08);
          }
        }
      }
      .blame {
        width: 248px;
        margin-right: 20px;
        .professional_img_conimg {
          width: 248px;
          height: 186px;
          overflow: hidden;
        }
      }
    }
  }

  .professional_img_contxt {
    height: 40px;
    line-height: 40px;
    width: 100%;
    color: #333333;
    font-size: 16px;
    background-color: #eaeaea;
    text-align: center;
  }

  .blameImg {
    width: 538px;
    margin-left: -130px;
    margin-top: -42px;
    position: relative;
    z-index: 2;
  }

  .professional_img_con {
    margin-right: 25px;
    width: 380px;
    display: inline-block;
  }

  .professional_img_con:last-child {
    margin-right: 0;
  }

  .professional_img_conimg {
    width: 380px;
    height: 262px;
    overflow: hidden;
  }

  .professional_img_conimg:hover img {
    transform: scale(1.08, 1.08);
  }

  .professional_img_conimg > img {
    width: 100%;
    transition: 0.6s ease-in-out;
  }

  .blame > .professional_img_conimg {
    width: 248px;
    height: 186px;
    overflow: hidden;
  }

  .professional_img_contxt {
    height: 40px;
    line-height: 40px;
    width: 100%;
    color: #333333;
    font-size: 16px;
    background-color: #eaeaea;
    text-align: center;
  }

  .blame {
    width: 248px;
    margin-right: 20px;
  }

  .blameImg {
    width: 538px;
    margin-left: -130px;
    margin-top: -42px;
    position: relative;
    z-index: 1;
  }

  .marginT80 {
    margin-top: 80px;
  }
}
</style>
