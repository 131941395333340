<template>
    <div class="company">
        <Banner
            :banner_img="resBanners"
            banner_en="ABOUT US"
            banner_cn="关于我们"
        ></Banner>
        <div class="pnav clear">
            <div
                :class="[item.key === componentTag.key ? 'on' : '', 'navItem']"
                v-for="item in pnavList"
                :key="item.key"
            >
                <router-link class="nuxtLink" :to="'/company?key=' + item.key">
                    {{ item.text }}
                </router-link>
            </div>
        </div>
        <!-- 核心模块 -->
        <transition mode="out-in">
            <component :is="componentTag.key"></component>
        </transition>
    </div>
</template>
<script>
import CompanyCulture from "@/components/company/CompanyCulture";
import CompanyProfile from "@/components/company/CompanyProfile";
import Memorabilia from "@/components/company/Memorabilia";
import ProfessionalService from "@/components/company/ProfessionalService";
import Banner from "@/components/common/Banner.vue";
export default {
    data() {
        return {
            componentTag: {
                index: 0,
                text: "公司简介",
                key: "CompanyProfile"
            },
            pnavList: [
                {
                    index: 0,
                    text: "公司简介",
                    key: "CompanyProfile"
                },
                {
                    index: 1,
                    text: "专业服务",
                    key: "ProfessionalService"
                },
                {
                    index: 2,
                    text: "大事记",
                    key: "Memorabilia"
                },
                {
                    index: 3,
                    text: "企业文化",
                    key: "CompanyCulture"
                }
            ],
            resBanners: []
        };
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        OnChangeCont(data) {
            // console.log(data)
            this.componentTag = data;
        },
        async getDataList() {
            let res = await this.$request.getImageAdvertisingList({
                advertisingPosition: "1"
            });
            if (res.code === 200) {
                this.resBanners = []
                this.resBanners = res.rows;
            }
        }
    },
    watch: {
        // 监听路由发生改变
        $route: {
            handler(newVal, oldval) {
                if (newVal != oldval && newVal.query.key) {
                    let resList = this.pnavList.filter(item => {
                        // console.log(newVal.query.key);
                        // console.log(item.key);
                        return newVal.query.key === item.key;
                    });
                    this.componentTag = resList[0];
                }
            },
            immediate: true
        }
    },
    components: {
        CompanyCulture,
        CompanyProfile,
        Memorabilia,
        ProfessionalService,
        Banner
    }
};
</script>
<style lang="scss">
.company {
    max-width: 100%;
    overflow: hidden;
    .pnav {
        width: auto;
        height: 2.92vw;
        line-height: 2.92vw;
        cursor: pointer;
        text-align: center;
        margin: -2.92vw auto 0 auto;
        color: #333333;
        position: relative;
        z-index: 11;
        font-size: 0;
        word-spacing: -0.05vw;
        text-align: center;
        .navItem {
            display: inline-block;
            width: 15.63vw;
            border: 0.05vw solid #eee;
            font-size: 0.83vw;
            background-color: #ffffff;
            &.on {
                color: #333333;
                border: 0.05vw solid #f3ca00;
                background-color: #f3ca00;
            }
            .nuxtLink {
                display: inline-block;
                width: 100%;
            }
        }
    }
}
@media (min-width: 1920px) {
    .company {
        max-width: 100%;
        overflow: hidden;
        .pnav {
            width: auto;
            height: 56px;
            line-height: 56px;
            cursor: pointer;
            text-align: center;
            margin: -56px auto 0 auto;
            color: #333333;
            position: relative;
            z-index: 11;
            font-size: 0;
            word-spacing: -1px;
            text-align: center;
            .navItem {
                width: 300px;
                border: 1px solid #eee;
                font-size: 16px;
                background-color: #ffffff;
                &.on {
                    color: #333333;
                    border: 1px solid #f3ca00;
                    background-color: #f3ca00;
                }
                .nuxtLink {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
}
</style>
